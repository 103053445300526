// city api
export const httpManageEditCityData = '/manage/artistmusic/processCity'
export const httpManageCityData = '/manage/artistmusic/viewCity'
// get dropdown provinces api
export const httpManageProvinceData = '/manage/province/viewProvince'
// customer api
export const httpManageCustomerData = '/manage/customer/viewCustomer'
export const httpManageCustomerEditData = '/manage/customer/processCustomer'
export const httpPostCusGroupVerification = '/manage/customer/updateCustomerGroupVerification'
// vehicle api
export const httpManageVehicleData = '/manage/vehicle/viewVehicle'
export const httpManageVehicleEditData = '/manage/vehicle/processVehicle'
// Category api
export const httpManageCategoryData = '/manage/services/category/viewCategory'
export const httpManageCategoryEditData = '/manage/services/category/processCategory'
// subcategory api
export const httpManageSubCategoryData = '/manage/services/subcategory/viewSubCategory'
export const httpManageSubCategoryEditData = '/manage/services/subcategory/processSubCategory'
// upload file api
export const httpManageUploadFileData = '/upload_file'

// booking-form api
export const httpManageBookingData = '/manage/services/bookingform/viewBooking'
export const httpManageBookingEditData = '/manage/services/bookingform/processBooking'

// product api
export const httpManageProductData = '/manage/services/testlist/viewTestList'
export const httpManageProductEditData = '/manage/services/testlist/processTestList'

// agent api
export const httpManageAgentData = '/manage/user/agents/viewAgent'
export const httpManageAgentEditData = '/manage/user/agents/processAgent'



// Vendor
export const httpPostVendorsData= "/manage/user/agents/processVendors"
export const httpGetVendorsData= "/manage/user/agents/viewVendors"

//Admin
export const httpManageAdminEditData="/manage/user/admin/processAdmin"
export const httpManageAdminData= "/manage/user/admin/viewAdmin"

export const toUploadFile="/upload_file"

// Time Slot
export const httpGetTimeSlots="/manage/services/timeslots/viewTimeSlot"
export const httpPostTimeSlots="/manage/services/timeslots/processTimeSlot"

// Admin Access Rights
export const toGetNavigation= "/manage/user/admin-access/get-navigation"

// get web pages
export const httpGetPages= "/manage/user/admin-access/get-all-pages"
export const httpGetUserPages= "/manage/user/admin-access/get-user-pages"

export const httpPostAdminSubmit = "/manage/user/admin-access/assign-pages-to-user"

// Live View
export const httpGetLiveView = "/getEmployeeLocation"
export const httpGetUpadatOnlineStatus='/manage/updateOnlineStatus'
export const httpPostAssignJob = "/executions/assignJob"

export const httpGetProductsForShop='/shop/getProducts'

// verify Email
export const httpGetVerifyEmail = '/manage/verifyEmail'
export const httpGetVerifyEmailWithoutToken = '/auth/verifyEmailWithoutToken'


export const uploadMultipleImages='/upload_multi_files'
export const httpGetRegistrationRequests='/manage/getRegistrationRequests'


export const httpGetPromoCode = '/manage/getPromoCode'
export const httpPostPromoCode = '/manage/postPromoCode'
export const httpVerifyPromo = '/manage/verifyPromo'

export const httpSearchCustomer = '/manage/getCustomerBySearch'

export const httpGetCustomerGroup = '/manage/getCustomerGroup'
export const httpPosttCustomerGroup = '/manage/postCustomerGroup'

// ============================================
// Web Pages

export const httpPostWebPages = "/manage/user/admin-access/add-update-pages"

//SHOP APIs

export const httpGetCategoriesForShop = '/shop/getCategories'
export const httpGetSubCategoriesForShop = "/shop/getSubCategories"
export const httpGetBookingFormDataForShop = "/shop/viewBooking"


// get All Images
export const httpGetAllImages = '/get-all-firebase-images'
// post All Images
export const httpPostAllImages = '/upload_file_on_firebase'
//repeat jobs
export const httpGetRepeatJobsData = '/manage/repeat-jobs'
export const httpPostRepeatJobsData = '/manage/post-repeat-jobs'

// home dashboard cards
export const httpGetJobs = '/executions/get-current-day-jobs'

// home dashboard graph
export const httpGetHomeGraph = '/executions/get-graph-data'



// brand
export const httpGetClients = '/manage/services/brand/viewBrand '
export const httpPostClients = '/manage/services/brand/processBrand '


export const httpGetBrands ='/manage/services/brand/viewBrand'
export const httpPostBrand = '/manage/services/brand/processBrand'

// contact us
export const httpPostContactUs = '/manage/send-mail-on-contact-us'

// banner images
export const httpDeleteBannerImages = '/delete-banner-image'


export const httpGetCustomerfamilyMembers='/manage/getCustomerFamilyMembers'
// Agents data for Dashboard
export const httpDataForDashboard='/manage/user/agents/get-data-for-dashboard'



// PAYTOk
export const httppostSound='/artist/create-sound'
export const httpGetArtistSound='/artist/get-artist-sounds'
export const httpGetArtistSoundWithLocking='/artist/get-all-sounds-with-locking'
export const httpGetAllSound='/artist/get-all-sounds'
export const httpPostCategory='/category/create'
export const httpPostUpdateCategory='/category/update'
export const httpGetCategory='/category/get-all'
export const httpGetAllUsers='/auth/get-users'
export const httpPostDeleteUser='/auth/delete-user'
export const httpGetUserById='/auth/get-user-by-id'
export const httpPostUseSound='/approval/use-sound'
export const httpPostApproveByArtist='/approval/by-artist'
export const httpPostApproveAllByArtist='/approval/by-artist-approve-all'
export const httpPostApproveByAdmin='/approval/by-admin'
export const httpPostDeleteSound='/artist/delete-sound'
export const httpGetApprovalById='/approval/get-approvals-by-id'
export const httpGetApprovals='/approval/get-approvals'
export const httpPostCreateBrandRequest='/brand/create-request'
export const httpGetBrandRequests='/brand/get-brand-requests'
export const httpGetBrandRequestsWithLocking='/brand/get-all-requests-with-locking'
export const httpGetAllBrandRequests='/brand/get-all-requests'
export const httpPostUser='/auth/signup'
export const httpGetArtistDashbord='/auth/get-artist-dashboard'
export const httpPostVerifyFollowers='/followers-scraper'
export const httpPostApplyBrand='/auth/apply-brand'
export const httpGetAllTransactions='/auth/get-all-transactions'
export const httpPostWithdrawtoBank='/auth/withdraw-to-bank-account'
export const httpPostFundUserWallet='/auth/fund-user-wallet'
export const httpPostAddClick='/artist/add-click'
export const httpGetUserByEmail='/auth/get-user-by-email'
export const httpPostEmail='/auth/send-password-reset-email'
export const httpGetUserByResetCode='/auth/get-user-by-reset-code'
export const httpPostResetPassword='/auth/reset-password'
export const httpPostAccountApprovalStatus='/auth/update-account-approval-status'
export const httpPostAccountInfluencerStatus='/auth/update-account-influencer-status'
export const httpPostCheckClickAndPendingReq='/approval/check-clicks-pending-requests'
export const httpGetUsersWithPendingApprovals='/approval/get-users-with-pending-approvals'
export const httpGetNotifications='/auth/get-notifications'
export const httpPostAddHoldSlot='/artist/add-hold-slot'
export const httpGetReservedSlots='/artist/get-sound-reserved-slots'
export const httpPostDeleteReservedSlots='/artist/delete-reserved-slots'
export const httpGetDashboardNew='/auth/get-dashboard-new'
export const httpPostUpdateAccoountType='/auth/update-user-account-role'
export const httpPostMakeSpecialAccount='/auth/update-user-account-speciality'


